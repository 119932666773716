figure[data-purpose=connection-status] {
    position: fixed;
    left: 50%;
    bottom: 1em;
    padding: 1em 2.5em;

    @include font-copy();
    font-size: 1rem;
    white-space: nowrap;
    color: $color-foreground;
    background-color: $color-background;
    border-radius: 0.2em;

    pointer-events: none;
    user-select: none;

    &:not([data-visible]) {
        transform: translate(-50%, 100%) translateY(1em);
        transition:
            transform $timing-move-time $timing-material-sharp;
    }
    &[data-visible] {
        transform: translate(-50%, 0);
        transition:
            transform $timing-move-time $timing-material-decelerate;
    }
}
