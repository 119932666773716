article.playlist-editor {
    $outer-padding: 3rem;

    display: flex;
    position: absolute;
    left: 0; right: 0;
    top: 0; bottom: 0;
    width: 100%; height: 100%;

    @include scrollbar;
    overflow-x: auto;
    overflow-y: hidden;

    padding-left: $outer-padding;
    &:after {
        content: "";
        flex: 0 0 $outer-padding;
    }

    > article.panel {
        $panel-horizontal-padding: 2rem;
        $panel-vertical-padding: 3rem;

        flex: 0 0 auto;
        position: relative;
        padding: $panel-vertical-padding $panel-horizontal-padding;

        @include scrollbar;
        overflow-x: hidden;
        overflow-y: scroll;

        color: $color-foreground;
        background-color: rgba(#000, 0.8);

        & + article.panel { margin-left: 2rem; }

        @import "playlist-editor-panels";
    }
    &:not([data-ready]) > article.panel {
        transform: perspective(600px) rotateY(-20deg) translateX(-20%);
        transform-origin: right;
        opacity: 0;
    }
    &[data-ready] > article.panel {
        $num-panels: 4;

        transform: perspective(600px);
        transform-origin: right;
        opacity: 1;

        transition:
            transform 0.6s $timing-material-decelerate,
            opacity 0.8s $timing-material-standard;

        @for $i from 1 through $num-panels {
            &:nth-child(#{$i}) {
                transition-delay: $i * 0.2s;
            }
        }
    }
}
