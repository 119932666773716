&.add-request {
    $num-steps: 3;

    width: 20rem;

    @include loading-overlay;

    [data-openvr] & {
        display: none;
    }

    > article {
        position: absolute;
        top: 0;
        left: 0; right: 0;
        width: 100%;
        padding: $panel-vertical-padding $panel-horizontal-padding;

        > h1 { margin-bottom: 1rem; }
        > p, time { margin: .5rem 0; }

        button {
            width: calc(100% + #{$panel-horizontal-padding * 2});
            margin: 2rem #{-$panel-horizontal-padding} 0;

            & + button { margin-top: 0; }

            &[data-action=swap-title-artist] {
                @include button-no-style;

                display: inline;

                float: right;
            }

            &[data-action=cancel] { @include button-secondary; }
        }

        > img {
            display: block;
            width: 100%;
            margin-bottom: 1rem;

            border: 0.1rem solid desaturate($color-accent, 20%);

            &[src=""] {
                content: $gif-transparent;
                position: relative;
                height: 0;
                padding-bottom: 9 / 16 * 100%;

                background: repeating-linear-gradient(45deg,
                    $color-accent,
                    $color-accent 10px,
                    transparentize($color-accent, 0.1) 10px,
                    transparentize($color-accent, 0.1) 20px);
            }
        }

        [data-purpose=duration] {
            &:before { content: "Duration: "; }
            &:empty { display: none; }
        }

        [data-purpose=error] {
            font-size: 0.8rem;
            word-break: break-word;
            white-space: pre-line;

            &:before {
                content: "Error";

                display: block;
                margin-bottom: 0.65em;

                font-weight: 700;
                font-size: 1rem;

                border-bottom: 0.2em solid $color-error;
            }

            &:empty { display: none; }
        }
    }

    @for $i from 1 through $num-steps {
        &[data-step="#{$i}"] {
            > article {
                transform: translateX(-100%);
                opacity: 0;
                visibility: hidden;
                max-height: 0;
                overflow-y: hidden;

                transition:
                    transform 0.3s $timing-material-standard,
                    opacity 0.3s $timing-material-standard,
                    visibility 0s 0.3s,
                    max-height 0s 0.3s;
            }

            > article:nth-child(#{$i}) {
                transform: none;
                opacity: 1;
                visibility: visible;
                max-height: 500vh;

                transition:
                    transform 0.3s $timing-material-standard,
                    opacity 0.3s $timing-material-standard,
                    visibility 0s,
                    max-height 0s;
            }

            > article:nth-child(#{$i}) ~ article {
                transform: translateX(100%);
                opacity: 0;
                visibility: hidden;
                max-height: 0;
                overflow-y: hidden;

                transition:
                    transform 0.3s $timing-material-standard,
                    opacity 0.3s $timing-material-standard,
                    visibility 0s 0.3s,
                    max-height 0s 0.3s;
            }
        }
    }
}
