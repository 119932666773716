article.thumbnail-generator {
    > canvas {
        display: block;
        width: 30rem;
        height: auto;
        margin-bottom: 1rem;
    }

    > label {
        display: block;

        margin-bottom: 1rem;

        > span {
            margin-right: 1rem;

            vertical-align: top;
        }

        > textarea {
            width: 10em;
            height: 1.3 * 4em;

            line-height: 1.3;
        }
    }
}
