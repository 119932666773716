select {
    appearance: none;
    border: none;
    outline: none;
    background: transparent;
    border-radius: 0;

    display: block;
    width: 100%;
    padding: 0.25rem 0.5rem 0;
    margin: 0.5rem 0 1rem;

    background-color: $color-background;
    color: $color-foreground;

    border: 0.1rem solid;
    border-color: desaturate($color-accent, 20%);

    @include font-copy;
    font-size: 1rem;

    &:hover, &:focus {
        border-color: $color-accent;

        transition: border-color 0.2s $timing-material-standard;
    }
    &:invalid { border-color: $color-error; }
    &[disabled], &[readonly] {
        cursor: default;
        border-color: desaturate($color-accent, 60%);

        transition: border-color 0.4s $timing-material-standard;
    }
}
