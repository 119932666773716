@font-face {
  font-family: "Silkscreen (Ruirize)";
  src: url("../assets/font/silkscreen-ruirize.woff2") format("woff2"), url("../assets/font/silkscreen-ruirize.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80); }

.gu-hide {
  display: none !important; }

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important; }

.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20); }

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html {
  background: url(/images/background.svg) top left/cover; }
  html[data-openvr] {
    background: transparent;
    font-size: 125%; }

html, body {
  font-family: "Biryani", sans-serif;
  font-style: normal;
  font-weight: 300; }

h1, h2, h3, legend {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500; }

h1 {
  font-size: 1.75em; }

h2 {
  font-size: 1.35em; }

h3 {
  font-size: 1.2em; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

button {
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  border-radius: 0;
  display: block;
  width: 100%;
  padding: 1.25rem 1.75rem;
  margin: 1rem 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  text-transform: uppercase;
  letter-spacing: 1.4;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #cc67b4;
  color: #ead8e2; }
  button:hover {
    cursor: pointer;
    transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  button:active {
    transition: none; }
  button[disabled] {
    cursor: default;
    transition: background-color 0.4s cubic-bezier(0.4, 0, 0.2, 1); }
  button:hover {
    background-color: #d27ebd; }
  button:active {
    background-color: #e053bf; }
  button[disabled] {
    background-color: #a3909f; }

input {
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  border-radius: 0;
  display: block;
  width: 100%;
  padding: 0.25rem 0.5rem 0;
  margin: 0.5rem 0 1rem;
  background-color: #1a1714;
  color: #ead8e2;
  border: 0.1rem solid;
  border-color: #b87ba9;
  font-family: "Biryani", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  transition: border-color 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  input:hover {
    cursor: text; }
  input:focus {
    border-color: #cc67b4;
    transition: border-color 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  input:invalid {
    border-color: #f11850; }
  input[disabled], input[readonly] {
    cursor: default;
    border-color: #9a9a9a;
    transition: border-color 0.4s cubic-bezier(0.4, 0, 0.2, 1); }

select {
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  border-radius: 0;
  display: block;
  width: 100%;
  padding: 0.25rem 0.5rem 0;
  margin: 0.5rem 0 1rem;
  background-color: #1a1714;
  color: #ead8e2;
  border: 0.1rem solid;
  border-color: #b87ba9;
  font-family: "Biryani", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem; }
  select:hover, select:focus {
    border-color: #cc67b4;
    transition: border-color 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  select:invalid {
    border-color: #f11850; }
  select[disabled], select[readonly] {
    cursor: default;
    border-color: #9a9a9a;
    transition: border-color 0.4s cubic-bezier(0.4, 0, 0.2, 1); }

svg.icon {
  width: 1.5em;
  height: 1.5em;
  fill: currentColor; }

article.track, li.track-item {
  display: flex;
  cursor: move; }
  article.track > *:not(aside), li.track-item > *:not(aside) {
    padding: 0.25rem 0.75rem; }
    article.track > *:not(aside) + *, li.track-item > *:not(aside) + * {
      padding-left: 0; }
  article.track > .artist,
  article.track > .title, li.track-item > .artist,
  li.track-item > .title {
    max-width: 20em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  article.track:not(:hover), li.track-item:not(:hover) {
    color: #ead8e2;
    background-color: transparent;
    transition: color 0.2s cubic-bezier(0.4, 0, 0.6, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.6, 1); }
  article.track:hover, article.track.gu-mirror, article.track.gu-transit, li.track-item:hover, li.track-item.gu-mirror, li.track-item.gu-transit {
    color: #1a1714;
    background-color: #ead8e2;
    transition: color 0.2s cubic-bezier(0, 0, 0.2, 1), background-color 0.2s cubic-bezier(0, 0, 0.2, 1); }
  article.track[data-deleted], article.track[data-archived], li.track-item[data-deleted], li.track-item[data-archived] {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 1, 1), color 0.2s cubic-bezier(0.4, 0, 0.6, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.6, 1); }
    article.track[data-deleted] ~ article.track, article.track[data-deleted] ~ li.track-item, article.track[data-archived] ~ article.track, article.track[data-archived] ~ li.track-item, li.track-item[data-deleted] ~ article.track, li.track-item[data-deleted] ~ li.track-item, li.track-item[data-archived] ~ article.track, li.track-item[data-archived] ~ li.track-item {
      transform: translateY(-100%);
      transition: transform 0.2s cubic-bezier(0.4, 0, 0.6, 1), color 0.2s cubic-bezier(0.4, 0, 0.6, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.6, 1); }
  article.track.gu-transit, li.track-item.gu-transit {
    opacity: 1; }
  article.track.gu-mirror, li.track-item.gu-mirror {
    display: none; }
  article.track > aside, li.track-item > aside {
    position: absolute;
    z-index: 1;
    padding: 0.25rem 0.75rem;
    background-color: #ead8e2;
    transform-origin: top;
    user-select: none;
    cursor: default; }
    article.track > aside > button, li.track-item > aside > button {
      width: auto;
      padding: 0;
      margin: 0;
      font-size: 1rem;
      text-transform: none;
      letter-spacing: normal;
      background-color: transparent;
      color: inherit;
      transition: none;
      display: inline-block;
      margin-right: 0.5rem;
      vertical-align: top; }
      article.track > aside > button:hover, li.track-item > aside > button:hover {
        background-color: transparent;
        transition: none; }
      article.track > aside > button:active, li.track-item > aside > button:active {
        background-color: transparent;
        transition: none; }
      article.track > aside > button[disabled], li.track-item > aside > button[disabled] {
        background-color: transparent;
        transition: none; }
  article.track:not(:hover) > aside, article.track.gu-transit > aside, article.track:active > aside:not(:active), li.track-item:not(:hover) > aside, li.track-item.gu-transit > aside, li.track-item:active > aside:not(:active) {
    color: transparent;
    background-color: transparent;
    transform: translate(0, calc(0.5rem + 1.75em)) scaleY(0.0001);
    pointer-events: none;
    transition: background-color 0.2s cubic-bezier(0.4, 0, 0.6, 1), color 0.2s cubic-bezier(0.4, 0, 0.6, 1), transform 0s 0.3s; }
  article.track:hover:not(.gu-transit):not(:active) > aside, article.track:hover:not(.gu-transit) > aside:active, li.track-item:hover:not(.gu-transit):not(:active) > aside, li.track-item:hover:not(.gu-transit) > aside:active {
    color: #1a1714;
    background-color: #ead8e2;
    transform: translate(0, calc(0.5rem + 1.75em));
    pointer-events: auto;
    transition: background-color 0s, color 0.2s 0.7s cubic-bezier(0, 0, 0.2, 1), transform 0.3s 0.4s cubic-bezier(0, 0, 0.2, 1); }

li.track-item {
  display: table-row;
  list-style: none; }
  li.track-item > *:not(aside) {
    display: table-cell; }

article.broadcast-handover {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 3rem 5rem;
  color: #ead8e2;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  transform: translate(-50%, -50%); }
  article.broadcast-handover > h1 {
    font-size: 2.5rem; }
    article.broadcast-handover > h1 + p {
      margin-top: 1.5em; }
  article.broadcast-handover > p {
    font-size: 1.25rem;
    line-height: 1.6;
    white-space: pre-line; }

figure[data-purpose=connection-status] {
  position: fixed;
  left: 50%;
  bottom: 1em;
  padding: 1em 2.5em;
  font-family: "Biryani", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  white-space: nowrap;
  color: #ead8e2;
  background-color: #1a1714;
  border-radius: 0.2em;
  pointer-events: none;
  user-select: none; }
  figure[data-purpose=connection-status]:not([data-visible]) {
    transform: translate(-50%, 100%) translateY(1em);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.6, 1); }
  figure[data-purpose=connection-status][data-visible] {
    transform: translate(-50%, 0);
    transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1); }

article.playlist-editor {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 3rem; }
  html[data-openvr] article.playlist-editor::-webkit-scrollbar {
    width: 1.225em;
    height: 1.225em; }
  html:not([data-openvr]) article.playlist-editor::-webkit-scrollbar {
    width: 0.7em;
    height: 0.7em; }
  article.playlist-editor::-webkit-scrollbar-button {
    display: none; }
  article.playlist-editor::-webkit-scrollbar-track-piece {
    background: #2f2a29; }
  article.playlist-editor::-webkit-scrollbar-thumb {
    background: #ead8e2; }
  article.playlist-editor:after {
    content: "";
    flex: 0 0 3rem; }
  article.playlist-editor > article.panel {
    flex: 0 0 auto;
    position: relative;
    padding: 3rem 2rem;
    overflow-x: hidden;
    overflow-y: scroll;
    color: #ead8e2;
    background-color: rgba(0, 0, 0, 0.8); }
    html[data-openvr] article.playlist-editor > article.panel::-webkit-scrollbar {
      width: 1.225em;
      height: 1.225em; }
    html:not([data-openvr]) article.playlist-editor > article.panel::-webkit-scrollbar {
      width: 0.7em;
      height: 0.7em; }
    article.playlist-editor > article.panel::-webkit-scrollbar-button {
      display: none; }
    article.playlist-editor > article.panel::-webkit-scrollbar-track-piece {
      background: #2f2a29; }
    article.playlist-editor > article.panel::-webkit-scrollbar-thumb {
      background: #ead8e2; }
    article.playlist-editor > article.panel + article.panel {
      margin-left: 2rem; }
    article.playlist-editor > article.panel.add-request {
      width: 20rem; }
      article.playlist-editor > article.panel.add-request > figure.loading-overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80%;
        z-index: 2;
        text-align: center;
        pointer-events: none;
        transform: translate(-50%, -50%); }
        article.playlist-editor > article.panel.add-request > figure.loading-overlay > svg {
          width: 4em;
          height: 4em;
          animation: loading-spin 1s infinite linear; }

@keyframes loading-spin {
  from {
    transform: rotate(360deg); }
  to {
    transform: rotate(0deg); } }
      article.playlist-editor > article.panel.add-request:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(26, 23, 20, 0.75);
        cursor: wait; }
      article.playlist-editor > article.panel.add-request[data-loading] > figure.loading-overlay {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1), visibility 0s; }
      article.playlist-editor > article.panel.add-request[data-loading]:after {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1), visibility 0s; }
      article.playlist-editor > article.panel.add-request:not([data-loading]) > figure.loading-overlay {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1), visibility 0s 0.2s; }
      article.playlist-editor > article.panel.add-request:not([data-loading]):after {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1), visibility 0s 0.2s; }
      [data-openvr] article.playlist-editor > article.panel.add-request {
        display: none; }
      article.playlist-editor > article.panel.add-request > article {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 3rem 2rem; }
        article.playlist-editor > article.panel.add-request > article > h1 {
          margin-bottom: 1rem; }
        article.playlist-editor > article.panel.add-request > article > p, article.playlist-editor > article.panel.add-request > article time {
          margin: .5rem 0; }
        article.playlist-editor > article.panel.add-request > article button {
          width: calc(100% + 4rem);
          margin: 2rem -2rem 0; }
          article.playlist-editor > article.panel.add-request > article button + button {
            margin-top: 0; }
          article.playlist-editor > article.panel.add-request > article button[data-action=swap-title-artist] {
            width: auto;
            padding: 0;
            margin: 0;
            font-size: 1rem;
            text-transform: none;
            letter-spacing: normal;
            background-color: transparent;
            color: #ead8e2;
            transition: none;
            display: inline;
            float: right; }
            article.playlist-editor > article.panel.add-request > article button[data-action=swap-title-artist]:hover {
              background-color: transparent;
              transition: none; }
            article.playlist-editor > article.panel.add-request > article button[data-action=swap-title-artist]:active {
              background-color: transparent;
              transition: none; }
            article.playlist-editor > article.panel.add-request > article button[data-action=swap-title-artist][disabled] {
              background-color: transparent;
              transition: none; }
          article.playlist-editor > article.panel.add-request > article button[data-action=cancel] {
            background-color: #733f64;
            color: #ead8e2; }
            article.playlist-editor > article.panel.add-request > article button[data-action=cancel]:hover {
              background-color: #d27ebd; }
            article.playlist-editor > article.panel.add-request > article button[data-action=cancel]:active {
              background-color: #e053bf; }
            article.playlist-editor > article.panel.add-request > article button[data-action=cancel][disabled] {
              background-color: #a3909f; }
        article.playlist-editor > article.panel.add-request > article > img {
          display: block;
          width: 100%;
          margin-bottom: 1rem;
          border: 0.1rem solid #b87ba9; }
          article.playlist-editor > article.panel.add-request > article > img[src=""] {
            content: url("data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==");
            position: relative;
            height: 0;
            padding-bottom: 56.25%;
            background: repeating-linear-gradient(45deg, #cc67b4, #cc67b4 10px, rgba(204, 103, 180, 0.9) 10px, rgba(204, 103, 180, 0.9) 20px); }
        article.playlist-editor > article.panel.add-request > article [data-purpose=duration]:before {
          content: "Duration: "; }
        article.playlist-editor > article.panel.add-request > article [data-purpose=duration]:empty {
          display: none; }
        article.playlist-editor > article.panel.add-request > article [data-purpose=error] {
          font-size: 0.8rem;
          word-break: break-word;
          white-space: pre-line; }
          article.playlist-editor > article.panel.add-request > article [data-purpose=error]:before {
            content: "Error";
            display: block;
            margin-bottom: 0.65em;
            font-weight: 700;
            font-size: 1rem;
            border-bottom: 0.2em solid #f11850; }
          article.playlist-editor > article.panel.add-request > article [data-purpose=error]:empty {
            display: none; }
      article.playlist-editor > article.panel.add-request[data-step="1"] > article {
        transform: translateX(-100%);
        opacity: 0;
        visibility: hidden;
        max-height: 0;
        overflow-y: hidden;
        transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0s 0.3s, max-height 0s 0.3s; }
      article.playlist-editor > article.panel.add-request[data-step="1"] > article:nth-child(1) {
        transform: none;
        opacity: 1;
        visibility: visible;
        max-height: 500vh;
        transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0s, max-height 0s; }
      article.playlist-editor > article.panel.add-request[data-step="1"] > article:nth-child(1) ~ article {
        transform: translateX(100%);
        opacity: 0;
        visibility: hidden;
        max-height: 0;
        overflow-y: hidden;
        transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0s 0.3s, max-height 0s 0.3s; }
      article.playlist-editor > article.panel.add-request[data-step="2"] > article {
        transform: translateX(-100%);
        opacity: 0;
        visibility: hidden;
        max-height: 0;
        overflow-y: hidden;
        transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0s 0.3s, max-height 0s 0.3s; }
      article.playlist-editor > article.panel.add-request[data-step="2"] > article:nth-child(2) {
        transform: none;
        opacity: 1;
        visibility: visible;
        max-height: 500vh;
        transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0s, max-height 0s; }
      article.playlist-editor > article.panel.add-request[data-step="2"] > article:nth-child(2) ~ article {
        transform: translateX(100%);
        opacity: 0;
        visibility: hidden;
        max-height: 0;
        overflow-y: hidden;
        transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0s 0.3s, max-height 0s 0.3s; }
      article.playlist-editor > article.panel.add-request[data-step="3"] > article {
        transform: translateX(-100%);
        opacity: 0;
        visibility: hidden;
        max-height: 0;
        overflow-y: hidden;
        transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0s 0.3s, max-height 0s 0.3s; }
      article.playlist-editor > article.panel.add-request[data-step="3"] > article:nth-child(3) {
        transform: none;
        opacity: 1;
        visibility: visible;
        max-height: 500vh;
        transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0s, max-height 0s; }
      article.playlist-editor > article.panel.add-request[data-step="3"] > article:nth-child(3) ~ article {
        transform: translateX(100%);
        opacity: 0;
        visibility: hidden;
        max-height: 0;
        overflow-y: hidden;
        transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0s 0.3s, max-height 0s 0.3s; }
    article.playlist-editor > article.panel.edit-list > h1 {
      margin-bottom: 1rem; }
    article.playlist-editor > article.panel.edit-list > p {
      margin: 0.5rem 0; }
      article.playlist-editor > article.panel.edit-list > p[data-purpose=estimated-time-remaining]:not(:empty):before {
        content: "Approx. Time Remaining: "; }
    article.playlist-editor > article.panel.edit-list > ol {
      display: table; }
    article.playlist-editor > article.panel.edit-stream > h1 {
      margin-bottom: 1rem; }
    article.playlist-editor > article.panel.edit-stream > p[data-purpose=last-modified]:not(:empty):before {
      content: "Last updated by ";
      white-space: nowrap; }
  article.playlist-editor:not([data-ready]) > article.panel {
    transform: perspective(600px) rotateY(-20deg) translateX(-20%);
    transform-origin: right;
    opacity: 0; }
  article.playlist-editor[data-ready] > article.panel {
    transform: perspective(600px);
    transform-origin: right;
    opacity: 1;
    transition: transform 0.6s cubic-bezier(0, 0, 0.2, 1), opacity 0.8s cubic-bezier(0.4, 0, 0.2, 1); }
    article.playlist-editor[data-ready] > article.panel:nth-child(1) {
      transition-delay: 0.2s; }
    article.playlist-editor[data-ready] > article.panel:nth-child(2) {
      transition-delay: 0.4s; }
    article.playlist-editor[data-ready] > article.panel:nth-child(3) {
      transition-delay: 0.6s; }
    article.playlist-editor[data-ready] > article.panel:nth-child(4) {
      transition-delay: 0.8s; }

article.playlist-viewer {
  max-width: 40em;
  height: 100%;
  padding: 4rem 2rem;
  margin: 0 2em;
  font-size: 2rem;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.8); }
  @media screen and (max-width: 650px) {
    article.playlist-viewer {
      font-size: 4.92308vw; } }
  html[data-openvr] article.playlist-viewer::-webkit-scrollbar {
    width: 1.225em;
    height: 1.225em; }
  html:not([data-openvr]) article.playlist-viewer::-webkit-scrollbar {
    width: 0.7em;
    height: 0.7em; }
  article.playlist-viewer::-webkit-scrollbar-button {
    display: none; }
  article.playlist-viewer::-webkit-scrollbar-track-piece {
    background: #2f2a29; }
  article.playlist-viewer::-webkit-scrollbar-thumb {
    background: #ead8e2; }
  article.playlist-viewer > h1, article.playlist-viewer > p {
    font-family: "Silkscreen (Ruirize)", Courier New, fantasy;
    font-style: normal;
    font-weight: 400;
    white-space: pre-line;
    color: #fff;
    text-shadow: 0.1em 0.1em 0 #000; }
    article.playlist-viewer > h1 + h1, article.playlist-viewer > p + h1 {
      margin-top: 1.5em; }
    article.playlist-viewer > h1 + p, article.playlist-viewer > p + p {
      margin-top: 1em; }
  article.playlist-viewer > h1 {
    font-size: 1em;
    user-select: none;
    pointer-events: none; }
  article.playlist-viewer > p {
    font-size: 0.8em; }
  article.playlist-viewer:not([data-ready]) {
    transform: perspective(600px) rotateY(-20deg) translateX(-20%);
    transform-origin: right;
    opacity: 0; }
  article.playlist-viewer[data-ready] {
    transform: perspective(600px);
    transform-origin: right;
    opacity: 1;
    transition: transform 0.6s cubic-bezier(0, 0, 0.2, 1), opacity 0.8s cubic-bezier(0.4, 0, 0.2, 1); }

article.thumbnail-generator > canvas {
  display: block;
  width: 30rem;
  height: auto;
  margin-bottom: 1rem; }

article.thumbnail-generator > label {
  display: block;
  margin-bottom: 1rem; }
  article.thumbnail-generator > label > span {
    margin-right: 1rem;
    vertical-align: top; }
  article.thumbnail-generator > label > textarea {
    width: 10em;
    height: 5.2em;
    line-height: 1.3; }
