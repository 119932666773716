&.edit-list {
    > h1 { margin-bottom: 1rem; }
    > p {
        margin: 0.5rem 0;

        &[data-purpose=estimated-time-remaining] {
            &:not(:empty):before {
                content: "Approx. Time Remaining: ";
            }
        }
    }

    > ol {
        display: table;
    }
}
