article.playlist-viewer {
    max-width: 40em;
    height: 100%;
    padding: 4rem 2rem;
    margin: 0 2em;

    font-size: 2rem;
    @media screen and (max-width: 650px) { font-size: width-proportional-size(325); }

    @include scrollbar;
    overflow-x: hidden;
    overflow-y: scroll;

    background-color: rgba(#000, 0.8);

    > h1, > p {
        @include font-stream();
        white-space: pre-line;
        color: #fff;
        text-shadow: 0.1em 0.1em 0 #000;

        & + h1 { margin-top: 1.5em; }
        & + p { margin-top: 1em; }
    }

    > h1 {
        font-size: 1em;

        user-select: none;
        pointer-events: none;
    }

    > p {
        font-size: 0.8em;
    }

    &:not([data-ready]) {
        transform: perspective(600px) rotateY(-20deg) translateX(-20%);
        transform-origin: right;
        opacity: 0;
    }
    &[data-ready] {
        transform: perspective(600px);
        transform-origin: right;
        opacity: 1;

        transition:
            transform 0.6s $timing-material-decelerate,
            opacity 0.8s $timing-material-standard;
    }
}
