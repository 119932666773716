$scrollbar-width: 0.7em;

$color-foreground: #ead8e2;
$color-accent: #cc67b4;
$color-background: #1a1714;

$color-success: #13e05e;
$color-warn: #f99127;
$color-error: #f11850;

$timing-tooltip-delay: 0.4s;
$timing-move-time: 0.3s;
$timing-fade-time: 0.2s;

$timing-material-standard: cubic-bezier(0.4, 0.0, 0.2, 1);
$timing-material-decelerate: cubic-bezier(0.0, 0.0, 0.2, 1);
$timing-material-accelerate: cubic-bezier(0.4, 0.0, 1, 1);
$timing-material-sharp: cubic-bezier(0.4, 0.0, 0.6, 1);

$gif-transparent: url("data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==");
