article.track, li.track-item {
    display: flex;

    cursor: move;

    > *:not(aside) {
        padding: 0.25rem 0.75rem;

        & + * { padding-left: 0; }
    }

    > .artist,
    > .title {
        max-width: 20em;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &:not(:hover) {
        color: $color-foreground;
        background-color: transparent;

        transition:
            color $timing-fade-time $timing-material-sharp,
            background-color $timing-fade-time $timing-material-sharp;
    }
    &:hover, &.gu-mirror, &.gu-transit {
        color: $color-background;
        background-color: $color-foreground;

        transition:
            color $timing-fade-time $timing-material-decelerate,
            background-color $timing-fade-time $timing-material-decelerate;
    }

    &[data-deleted], &[data-archived] {
        opacity: 0;
        pointer-events: none;

        transition:
            opacity $timing-fade-time $timing-material-accelerate,
            color $timing-fade-time $timing-material-sharp,
            background-color $timing-fade-time $timing-material-sharp;

        & ~ article.track, & ~ li.track-item {
            transform: translateY(-100%);

            transition:
                transform $timing-fade-time $timing-material-sharp,
                color $timing-fade-time $timing-material-sharp,
                background-color $timing-fade-time $timing-material-sharp;
        }
    }

    &.gu-transit { opacity: 1; }
    &.gu-mirror { display: none; }


    $tooltip-vertical-padding: 0.25rem;
    $tooltip-offset: calc(#{$tooltip-vertical-padding * 2} + 1.75em);

    > aside {
        position: absolute;
        z-index: 1;
        padding: $tooltip-vertical-padding 0.75rem;

        background-color: $color-foreground;
        transform-origin: top;
        user-select: none;
        cursor: default;

        > button {
            @include button-no-style(inherit);

            display: inline-block;
            margin-right: 0.5rem;

            vertical-align: top;
        }
    }
    &:not(:hover) > aside, &.gu-transit > aside, &:active > aside:not(:active) {
        color: transparent;
        background-color: transparent;
        transform: translate(0, #{$tooltip-offset}) scaleY(0.0001);

        pointer-events: none;

        transition:
            background-color $timing-fade-time $timing-material-sharp,
            color $timing-fade-time $timing-material-sharp,
            transform 0s $timing-move-time;
    }
    &:hover:not(.gu-transit):not(:active) > aside, &:hover:not(.gu-transit) > aside:active {
        color: $color-background;
        background-color: $color-foreground;
        transform: translate(0, #{$tooltip-offset});

        pointer-events: auto;

        transition:
            background-color 0s,
            color $timing-fade-time ($timing-move-time + $timing-tooltip-delay) $timing-material-decelerate,
            transform $timing-move-time $timing-tooltip-delay $timing-material-decelerate;
    }
}

li.track-item {
    display: table-row;

    list-style: none;

    > *:not(aside) {
        display: table-cell;
    }
}
