@mixin width-proportional-height($percentage: 100%) {
    &:before {
        content: "";
        display: block;
        padding-top: $percentage;
    }
}

@mixin font-header($weight: 500, $style: normal) {
    font-family: "Roboto", sans-serif;
    font-style: $style;
    font-weight: $weight;
}

@mixin font-copy($weight: 300, $style: normal) {
    font-family: "Biryani", sans-serif;
    font-style: $style;
    font-weight: $weight;
}

@mixin font-stream($weight: 400, $style: normal) {
    font-family: "Silkscreen (Ruirize)", Courier New, fantasy;
    font-style: $style;
    font-weight: $weight;
}

@mixin scrollbar() {
    html[data-openvr] &::-webkit-scrollbar { width: $scrollbar-width * 1.75; height: $scrollbar-width * 1.75; }
    html:not([data-openvr]) &::-webkit-scrollbar { width: $scrollbar-width; height: $scrollbar-width; }
    &::-webkit-scrollbar-button { display: none; }
    &::-webkit-scrollbar-track-piece { background: mix($color-foreground, $color-background, 10%); }
    &::-webkit-scrollbar-thumb { background: $color-foreground; }
}

@mixin loading-overlay {
    > figure.loading-overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80%;
        z-index: 2;

        text-align: center;

        pointer-events: none;
        transform: translate(-50%, -50%);

        > svg {
            width: 4em;
            height: 4em;

            animation: loading-spin 1s infinite linear;

            @keyframes loading-spin {
                from { transform: rotate(360deg); }
                to { transform: rotate(0deg); }
            }
        }
    }

    &:after {
        content: "";

        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;
        width: 100%; height: 100%;
        z-index: 1;

        background-color: rgba($color-background, 0.75);
        cursor: wait;
    }

    &[data-loading] {
        > figure.loading-overlay {
            visibility: visible;
            opacity: 1;

            transition:
                opacity $timing-fade-time $timing-material-standard,
                visibility 0s;
        }

        &:after {
            visibility: visible;
            opacity: 1;

            transition:
                opacity $timing-fade-time $timing-material-standard,
                visibility 0s;
        }
    }

    &:not([data-loading]) {
        > figure.loading-overlay {
            visibility: hidden;
            opacity: 0;

            transition:
                opacity $timing-fade-time $timing-material-standard,
                visibility 0s $timing-fade-time;
        }

        &:after {
            visibility: hidden;
            opacity: 0;

            transition:
                opacity $timing-fade-time $timing-material-standard,
                visibility 0s $timing-fade-time;
        }
    }
}
