@import "mixins";
@import "functions";
@import "variables";

@import "fonts";

@import "dragula/dist";

*, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    background: url(/images/background.svg) top left/cover;

    &[data-openvr] {
        background: transparent;

        font-size: 125%;
    }
}

html, body {
    @include font-copy;
}

h1, h2, h3, legend { @include font-header; }
h1 { font-size: 1.75em; }
h2 { font-size: 1.35em; }
h3 { font-size: 1.2em; }

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@import "inputs";
@import "components";
@import "blocks";
