article.broadcast-handover {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 3rem 5rem;

    color: $color-foreground;
    background-color: rgba(#000, 0.8);
    text-align: center;

    transform: translate(-50%, -50%);

    > h1 {
        font-size: 2.5rem;

        & + p { margin-top: 1.5em; }
    }

    > p {
        font-size: 1.25rem;
        line-height: 1.6;
        white-space: pre-line;
    }
}
