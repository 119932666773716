&.edit-stream {
    > h1 {
        margin-bottom: 1rem;
    }

    > p {
        &[data-purpose=last-modified] {
            &:not(:empty) {
                &:before {
                    content: "Last updated by ";

                    white-space: nowrap;
                }
            }
        }
    }
}
