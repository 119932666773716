@mixin button-default {
    background-color: $color-accent;
    color: $color-foreground;

    &:hover { background-color: mix($color-accent, $color-foreground, 80%); }
    &:active { background-color: saturate($color-accent, 20%); }
    &[disabled] { background-color: desaturate($color-accent, 40%); }
}

@mixin button-no-style($color: $color-foreground) {
    width: auto;
    padding: 0;
    margin: 0;

    font-size: 1rem;
    text-transform: none;
    letter-spacing: normal;

    background-color: transparent;
    color: $color;

    transition: none;

    &:hover { background-color: transparent; transition: none; }
    &:active { background-color: transparent; transition: none; }
    &[disabled] { background-color: transparent; transition: none; }
}

@mixin button-secondary {
    background-color: mix($color-accent, $color-background, 50%);
    color: $color-foreground;

    &:hover { background-color: mix($color-accent, $color-foreground, 80%); }
    &:active { background-color: saturate($color-accent, 20%); }
    &[disabled] { background-color: desaturate($color-accent, 40%); }
}

button {
    appearance: none;
    border: none;
    outline: none;
    background: transparent;
    border-radius: 0;

    display: block;
    width: 100%;
    padding: 1.25rem 1.75rem;
    margin: 1rem 0;

    @include font-header;
    font-size: 1.25rem;
    text-transform: uppercase;
    letter-spacing: 1.4;

    transition: background-color 0.2s $timing-material-standard;

    &:hover {
        cursor: pointer;

        transition: background-color 0.2s $timing-material-standard;
    }
    &:active { transition: none; }
    &[disabled] {
        cursor: default;

        transition: background-color 0.4s $timing-material-standard;
    }

    @include button-default;
}
